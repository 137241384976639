import React, { useCallback, useState } from "react";
import { useDropzone } from 'react-dropzone';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { ref, storage, uploadBytesResumable } from "./../firebase";

function UploadView({ getAllFiles }) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const onDrop = useCallback(acceptedFiles => {
    fileChooser(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  function fileChooser(data) {
    let file = data[0];
    const storeLocation = ref(storage, "/go_files/" + file.name);

    let uploadTask = uploadBytesResumable(storeLocation, file);

    // Update progress bar
    uploadTask.on(
      "state_changed",
      function progress(snapshot) {
        let percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(percentage);
      },
      function error(err) {
        Toastify({
          text: "❌ Try again",
          duration: 5000,
        }).showToast();
        console.error(err);
      },
      function complete() {
        Toastify({
          text: "✅ File uploaded",
          duration: 5000
        }).showToast();
        getAllFiles();
      }
    );
  }

  return (
    <>
      {/* DROPZONE */}
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <div className="dropzone postDrop">
              <span role="img" aria-label="teddy bear">🧸</span>
            </div> :
            <div className="dropzone preDrop">
              <img
                src={require("../Go_logo_500x500_tiny.png")}
                width="500"
                height="500"
                alt="GO • data bucket"
              />
              <h5>Drop files here</h5>
            </div>
        }
      </div>

      {/* PROGRESS BAR */}
      {uploadProgress !== 0 && uploadProgress !== 100 && (
        <progress value={uploadProgress} max="100" id="uploader" />
      )}
      {uploadProgress > 0 &&
        uploadProgress !== 100 &&
        <span>
          {uploadProgress.toFixed() + "%"}
        </span>
      }
    </>
  );
}

export default UploadView;
