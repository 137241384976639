import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
    getStorage,
    ref,
    listAll,
    getDownloadURL,
    uploadBytesResumable,
    getMetadata,
    deleteObject,
} from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

const reCaptchaTokens = {
    debugToken: process.env.REACT_APP_TOKEN_DEBUG,
    prodToken: process.env.REACT_APP_TOKEN_PROD,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

/** 
 * Enable localhost development
 * slå på kodesnutten under. Da får man feilmelding, 
 * men i consollen står det en kode som legges inn i 
 * console.firebase.com > app check > manage debug tokens > lim inn.
 * self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
 */

if (window.location.hostname === "localhost")
    window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = reCaptchaTokens.debugToken;

initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(`${reCaptchaTokens.prodToken}`),
    isTokenAutoRefreshEnabled: true,
});

// Get a reference to the storage service,
// which is used to create references in your storage bucket
const storage = getStorage(firebaseApp);

// Eksporter alle firebase metoder som skal brukes. De spesifikke
export {
    storage,
    ref,
    getDownloadURL,
    uploadBytesResumable,
    deleteObject,
    getMetadata,
    listAll,
};